import unhead_y0LmETKkvp from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WJ41vIzjPE from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_n2NOvVqW0N from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@6.0.6_@types+node@22.10.2_jit_4530c93da157add7d7fe94fb20329cf5/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import auth_vT9JWWT9pN from "/var/www/html/plugins/auth.ts";
import boot_OP3cd6SIk7 from "/var/www/html/plugins/boot.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
import payload_client_qB81Q0xG28 from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KJf7YQB9nx from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_TZMQynu7hx from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_MkGUCkHaiP from "/var/www/html/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_ioredis@5.4.1_magicast@_5e0f1f8209380bffb0b86ae3ac15bd22/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_SosaxONYqD from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.28.1_vite@6.0.6_@types+node@22.10.2_jiti@2.4._9662b288a1bff98b3284c8c1dcc0cea8/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_PiRyGZgvYe from "/var/www/html/node_modules/.pnpm/nuxt-auth-sanctum@0.4.18_magicast@0.3.5_rollup@4.28.1/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
export default [
  unhead_y0LmETKkvp,
  router_WJ41vIzjPE,
  _0_siteConfig_n2NOvVqW0N,
  auth_vT9JWWT9pN,
  boot_OP3cd6SIk7,
  window_mY4jZ4wBC5,
  payload_client_qB81Q0xG28,
  navigation_repaint_client_KJf7YQB9nx,
  chunk_reload_client_TZMQynu7hx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MkGUCkHaiP,
  plugin_SosaxONYqD,
  plugin_PiRyGZgvYe
]